<template>
  <div class="content">
    <div class="page">
      <div class="img_demo">
        <img src="" alt="" />
        <div class="right">
          <h2>审批服务</h2>
          <div class="message">
            整合各类服务软件的功能,涵盖全部业务,通过流程再造,打破信息孤岛,在互联网、多终端实现了‘一个平台内办两家事、业务办理全程电子化无纸化’”。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 10%;
  min-height: calc(100vh - 387px);
  .page {
    margin-top: 50px;
  }
  .img_demo {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    > img {
      display: inline-block;
      width: 50%;
      background: url("../../../../assets/image/ruralImg/img5.png") no-repeat;
      background-size: 100% 100%;
    }
    h2 {
      font-weight: bold;
      margin: 50px 30px 10px 50px;
    }
    .message {
      color: #6b6b6b;
      font-size: 16px;
      margin: 20px 30px 10px 50px;
    }
    .right {
      width: 50%;

      height: 400px;
      background-color: #fff;
    }
  }
}
</style>